@keyframes animate-logo {
    
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }

}

div.container {

    width: 100%;

    img.logo-bharath {
        margin-top: 25%;
        display: block;
        width: 100px;
        height: 105px;
        margin-left: auto;
        margin-right: auto;
        animation: animate-logo 5s linear 0s;
    }

    p.coming-soon-text{
        margin-top: 1rem;
        color:rgb(19, 182, 223);
        text-align: center;
        font-size: 2.5rem;
        text-transform: uppercase;
        animation: animate-logo 5s linear 0s;
        text-shadow: 0px 0px 2px #fff;
    }
}