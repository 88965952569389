html,
body,
* {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    overflow-x: hidden;
}

body,div.root,div.root>div*{
    height: 100%;
}

body{
    background-color: rgb(3, 50, 65);
}